import ReconnectingWebSocket from 'reconnecting-websocket';
import LoggerInterface from 'services/logger/Logger.interface';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type SubscriberType = (data: any) => void;

export enum SocketStates {
  INIT_STATE = 'init',
  OPEN_STATE = 'open',
  CLOSED_STATE = 'close',
  ERROR_STATE = 'error',
}

export default class Sockets {
  private client: ReconnectingWebSocket;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private stack: Record<string, any>[] = [];
  private subscribers: SubscriberType[] = [];
  private statusSubscribers: SubscriberType[] = [];
  private currentId = 0;
  private logger: LoggerInterface;

  state: SocketStates = SocketStates.INIT_STATE;

  constructor(url: string, logger: LoggerInterface) {
    this.logger = logger;
    this.client = new ReconnectingWebSocket(url);
    this.client.addEventListener('message', this.onMessage.bind(this));

    this.client.addEventListener('open', (message) => {
      this.logger.log('OPENED', message);
      this.setState(SocketStates.OPEN_STATE);
    });

    this.client.addEventListener('close', (message) => {
      this.logger.warn('CLOSED', message);
      this.setState(SocketStates.CLOSED_STATE);
    });

    this.client.addEventListener('error', (message) => {
      this.logger.error('ERROR', message);
      this.setState(SocketStates.ERROR_STATE);
    });
  }

  setState(state: SocketStates) {
    this.state = state;
    this.statusSubscribers.forEach((callback) => callback(state));
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onMessage(message: Record<string, any>) {
    if (message.data === 'ping') {
      this.logger.log('<-', 'PING');
      this.client.send('pong');
      this.logger.log('->', 'PONG');

      return;
    }

    try {
      const data = JSON.parse(message.data);
      const rid = data?.request?.rid;

      if (!rid) {
        this.logger.log(data?.request?.get, data);
        this.subscribers.forEach((callback) => callback(data));
        return;
      }

      const stackIndex = this.stack.findIndex(
        ({ message: msg }) => msg.rid === rid,
      );

      if (stackIndex === -1) {
        return;
      }

      const { result } = data;

      const [{ resolve, reject }] = this.stack.splice(stackIndex, 1);
      if (result === 0) {
        resolve(data);
      } else {
        reject(data);
      }

      this.logger.log('<-', data?.request?.get, data);
    } catch (e) {
      this.logger.error(e, message);
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  send<T>(data: Record<string, any>) {
    this.currentId += 1;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const message: Record<string, any> = {
      rid: this.currentId,
      ...data,
    };

    return new Promise<T>((resolve, reject) => {
      this.stack.push({ message, resolve, reject });
      this.logger.log('->', message?.get, message);
      this.client.send(JSON.stringify(message));
    });
  }

  onStatusChange(callback: SubscriberType) {
    this.statusSubscribers.push(callback);
  }

  subscribe(callback: SubscriberType) {
    this.subscribers.push(callback);
  }

  reconnect() {
    this.client.reconnect();
  }
}
