export enum LoggerDomains {
  GENERAL = 'general',
  SOCKETS = 'sockets',
  STORE = 'store',
}

export enum LoggerLevels {
  ALL = 0,
  WARNINGS = 1,
  ERRORS = 2,
  NONE = 3,
}

interface LoggerInterface {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  log(...messages: any[]): void;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  warn(...messages: any[]): void;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  error(...messages: any[]): void;
}

export default LoggerInterface;
