import LoggerInterface, { LoggerDomains } from './Logger.interface';

const ColorMap = {
  [LoggerDomains.GENERAL]: 'green',
  [LoggerDomains.SOCKETS]: '#FFD080',
  [LoggerDomains.STORE]: '#C3C2EF',
};

class ConsoleLogger implements LoggerInterface {
  private domain: LoggerDomains;
  private color: string = '';

  constructor(domain: LoggerDomains) {
    this.domain = domain;
    this.color = ColorMap[this.domain] || 'green';
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  log(...messages: any[]): void {
    // eslint-disable-next-line
    console.log(`%c[${this.domain}]`, `color: ${this.color}`, ...messages);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  warn(...messages: any[]): void {
    // eslint-disable-next-line
    console.warn(`%c[${this.domain}]`, `color: ${this.color}`, ...messages);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  error(...messages: any[]): void {
    // eslint-disable-next-line
    console.error(`%c[${this.domain}]`, `color: ${this.color}`, ...messages);
  }
}

export default ConsoleLogger;
