import ConsoleLogger from './ConsoleLogger';
import LoggerInterface, {
  LoggerLevels,
  LoggerDomains,
} from './Logger.interface';
import Logger from './Logger';
import NoneLogger from './NoneLogger';

const { REACT_APP_LOGGER, REACT_APP_LOGGER_LEVEL } = process.env;

const loggers: Record<string, LoggerInterface> = {};
const loggerFactory = (domain: LoggerDomains = LoggerDomains.GENERAL) => {
  const mapLogger = (loggerName: string) => {
    switch (loggerName) {
      case 'sentry':
      case 'console':
        return new ConsoleLogger(domain);
      default:
        return new NoneLogger();
    }
  };
  const mapErrorLevel = (level: string) => {
    switch (level) {
      case 'all':
        return LoggerLevels.ALL;
      case 'warnings':
        return LoggerLevels.WARNINGS;
      case 'errors':
        return LoggerLevels.ERRORS;
      case 'none':
        return LoggerLevels.NONE;
      default:
        return LoggerLevels.ERRORS;
    }
  };
  const createLogger = () => {
    const logger = mapLogger(REACT_APP_LOGGER || '');
    return new Logger(logger, {
      level: mapErrorLevel(REACT_APP_LOGGER_LEVEL || ''),
    });
  };

  loggers[domain] = loggers[domain] || createLogger();
  return loggers[domain];
};

const logger = loggerFactory();

export { LoggerDomains, loggerFactory, logger };
