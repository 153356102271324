import LoggerInterface, { LoggerLevels } from './Logger.interface';

type LoggerOptions = {
  level: LoggerLevels;
};

export default class Logger implements LoggerInterface {
  private logger: LoggerInterface;
  private level: LoggerLevels;

  constructor(logger: LoggerInterface, options: LoggerOptions) {
    this.logger = logger;
    this.level = options.level;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  log(...messages: any[]): void {
    if (this.level <= LoggerLevels.ALL) {
      this.logger.log(...messages);
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  warn(...messages: any[]) {
    if (this.level <= LoggerLevels.WARNINGS) {
      this.logger.warn(...messages);
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  error(...messages: any[]): void {
    if (this.level <= LoggerLevels.ERRORS) {
      this.logger.error(...messages);
    }
  }
}
