import React, { lazy } from 'react';
import { RecoilRoot } from 'recoil';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import ReactDOM, { Root } from 'react-dom/client';

import SportBookParams from './types/global';

import reportWebVitals from './reportWebVitals';
import config from './services/config';
import sockets from './services/sockets';
import { logger } from './services/logger';

const App = lazy(() => import('components/App'));

dayjs.extend(utc);
dayjs.extend(timezone);

let root: Root;

window.initSportBook = (params: SportBookParams) => {
  if (root) {
    logger.log('Root already defined. Unmount');
    root.unmount();
    sockets.reconnect();
  }

  const {
    nodeId,
    hash,
    currency,
    lang,
    routerPrefix = '',
    country = '',
  } = params;

  config.nodeId = nodeId;

  root = ReactDOM.createRoot(document.getElementById(nodeId) as HTMLElement);

  if (!root) {
    throw new Error(`Node "${nodeId}" not found`);
  }

  root.render(
    <RecoilRoot>
      <App
        hash={hash}
        currency={currency}
        lang={lang}
        country={country}
        routerPrefix={routerPrefix}
      />
    </RecoilRoot>,
  );

  reportWebVitals();
};

if (window?.sportBookParams?.nodeId) {
  window.initSportBook(window.sportBookParams);
}
