import { LoggerDomains, loggerFactory } from 'services/logger';
import Sockets, { SocketStates } from './Sockets';

const socketLogger = loggerFactory(LoggerDomains.SOCKETS);

const { REACT_APP_SOCKET_URL } = process.env;

export { SocketStates };

export default new Sockets(REACT_APP_SOCKET_URL || '', socketLogger);
